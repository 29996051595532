<template>
  <StatPanel class="big-number-stat-panel" v-bind="$props" :class="{ '-hide-title': !loading }">
    <h3 class="f-title tc">
      <span v-for="(number, index) in numbers" :key="index">
        <span v-if="colors" :style="{ color: colorArray[index] }">{{ number }}</span>
        <span v-else>{{ number }}</span>
        <span v-if="numbers.length > 1 && index !== numbers.length - 1"> - </span>
      </span>
    </h3>
    <h2 class="f-header tc">{{ title }}</h2>
  </StatPanel>
</template>

<script>
import StatPanel from './StatPanel';
import { useChartJSOptions } from '../../../compositions/useChartJSOptions';

export default {
  components: {
    StatPanel,
  },
  props: {
    ...StatPanel.props,
    numbers: {
      type: Array,
      required: false,
    },
    colors: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const { colorArray } = useChartJSOptions();

    return {
      colorArray,
    };
  },
};
</script>

<style lang="postcss" scoped>

.big-number-stat-panel {
  &.-hide-title {
    & >>> h2:first-child {
      display: none;
    }
  }

  & >>> .slotcontainer {
    margin: 0;
  }
}
</style>
