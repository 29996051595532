import { useI18n } from '@/core/shared/compositions';
import { crossEnv } from '@/core/shared/setup/configureEnvironment';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { RdYlGn11 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer';

export function useChartJSOptions (locale = crossEnv.BROWSER_LOCALE) {
  const colorArray = ['#EC4E36', '#4CA7F4', '#F9B141'];
  const rainbowArray = [...RdYlGn11, ...RdYlGn11, ...RdYlGn11, ...RdYlGn11];
  const i18n = useI18n();

  function getRainbowDataset (data) {
    return {
      backgroundColor: rainbowArray,
      data,
    };
  }

  function getSolidColorDataset (data, index = 0) {
    return {
      backgroundColor: colorArray[index],
      data,
    };
  }

  function getDefaultRadialChart (labels) {
    return {
      type: 'doughnut',
      data: {
        labels,
        datasets: [],
      },
      options: {
        legend: {
          position: 'left',
        },
        tooltips: {
          callbacks: {
            label (tooltipItem, data) {
              const name = data.labels[tooltipItem.index];
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              return `${name}: ${value.toLocaleString(locale)} ${i18n.t('Minutes')}`;
            },
          },
        },
      },
    };
  }

  function getDefaultBarChart (labels) {
    return {
      type: 'bar',
      data: {
        labels,
        datasets: [],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label (tooltipItem) {
              return `${tooltipItem.yLabel.toLocaleString(locale)} ${i18n.t('Minutes')}`;
            },
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              callback (value) {
                return value.toLocaleString(locale);
              },
            },
            scaleLabel: {
              display: true,
              labelString: i18n.t('Minutes'),
            },
          }],
        },
      },
    };
  };

  function localizeData (data) {
    if (typeof data === 'number') {
      return data.toLocaleString(locale);
    }
    return data;
  }

  return {
    colorArray,
    getRainbowDataset,
    getSolidColorDataset,
    getDefaultRadialChart,
    getDefaultBarChart,
    localizeData,
  };
};
