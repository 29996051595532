<template>
  <div class="more-info-tooltip">
    <div class="hover f5" @mouseover="onMouseOver" @mouseout="onMouseOut">?</div>
    <div
      class="popup"
      ref="popup"
      :class="{ '-show': show }"
      :style="{ left: left }"
    >
      <div class="innercontent" >
        <slot />
      </div>
      <div class="arrow-down" :style="{ left: arrowLeft }"></div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

// There is no collision detection for left side yet... but that's rare.
export default {
  setup () {
    const popup = ref(null);
    const left = ref('0px');
    const arrowLeft = ref('0px');
    const show = ref(false);

    function onMouseOver (e) {
      const boundingRect = popup.value.getBoundingClientRect();
      const rightOffset = window.innerWidth - boundingRect.right;
      if (rightOffset < 15) {
        const offset = rightOffset - 45;
        left.value = `${offset}px`;
        arrowLeft.value = `${offset * -1}px`;
      }
      show.value = true;
    }

    function onMouseOut (e) {
      show.value = false;
    }

    return {
      popup,
      left,
      arrowLeft,
      show,
      onMouseOver,
      onMouseOut,
    };
  },
};
</script>

<style lang="postcss" scoped>
@import '../styles';

.more-info-tooltip {
  display: inline-block;
  position: relative;

  & .hover {
    font-weight: bold;
    color: var(--colorManatee);
    border-radius: 50%;
    border: solid 0.2rem var(--colorManatee);
    line-height: 1.6rem;
    padding-left: 0.1rem;
    width: 2rem;
    height: 2rem;
    text-align: center;
    cursor: help;
    margin: 0 var(--spacingSm);
  }

  & .popup {
    position: absolute;
    background-color: var(--colorWhite);
    box-shadow: var(--shadowMd);
    bottom: 3rem;
    width: 20rem;
    padding: var(--spacingSm);
    transform-origin: bottom center;
    transform: scaleY(0) translateX(calc(-50% + 2.5rem)) translateZ(0);
    transition: transform 0.15s ease 0.15s, opacity 0.15s ease 0.15s;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;

    & .innercontent {
      transform: translateZ(0);
      opacity: 0;
      will-change: opacity;
      transition: opacity 0.15s ease;
    }

    & .arrow-down {
      position: absolute;
      bottom: -0.8rem;
      transform: translateX(calc(10rem - 50%));
      width: 0;
      height: 0;
      border-left: 0.8rem solid transparent;
      border-right: 0.8rem solid transparent;
      border-top: 0.8rem solid var(--colorWhite);
    }
  }

  & .popup.-show {
    transform: scale(1) translateX(calc(-50% + 2.5rem)) translateZ(0);
    transition: transform 0.15s ease, opacity 0.15s ease;
    opacity: 1;
    pointer-events: all;

    & .innercontent {
      opacity: 1;
      transform: translateZ(0);
      transition: opacity 0.3s ease 0.15s;
    }
  }
}
</style>
