<template>
  <StatPanel class="pie-chart-stat-panel" :style="{ 'min-height': (loading) ? minHeightLoading : 'auto' }" v-bind="$props">
    <div class="canvascontainer">
      <canvas ref="canvasElement"></canvas>
    </div>
  </StatPanel>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import StatPanel from './StatPanel';
import Chart from 'chart.js';
import deepmerge from 'deepmerge';
import { debounce } from 'lodash-es';

export default {
  components: {
    StatPanel,
  },
  props: {
    ...StatPanel.props,
    minHeightLoading: {
      type: String,
      default: '30rem',
    },
    chartOptions: {
      type: Object,
      required: false,
    },
  },
  setup (props) {
    const canvasElement = ref(null);
    let chart;

    function initChart () {
      const chartOptions = deepmerge({
        options: {
          aspectRatio: 1.5,
          defaultFontFamily: "'Open Sans', sans-serif",
          defaultFontColor: '#182942',
        },
      }, props.chartOptions);

      chart = new Chart(canvasElement.value, chartOptions);
    }

    function updateChart () {
      chart.data.labels = props.chartOptions.data.labels;
      chart.data.datasets = props.chartOptions.data.datasets;
      chart.update();
    }

    function watchWindowResizing () {
      if (window) {
        const debouncedListener = debounce(() => {
          initChart();
        }, 300);
        window.addEventListener('resize', () => {
          if (chart) {
            chart.destroy();
            chart = null;
          }
          debouncedListener();
        });
      }
    }

    watchWindowResizing();

    watch(() => props.chartOptions, (newVal) => {
      if (newVal) {
        if (chart) updateChart();
        else initChart();
      }
    });

    return {
      canvasElement,
    };
  },
};
</script>

<style lang="postcss" scoped>
.pie-chart-stat-panel {
  position: relative;

  & >>> .loading {
    position: absolute;
    left: calc(50% - 3rem);
    top: calc(50% - 3rem);
  }

  & .canvascontainer {
    position: relative;
    width: 100%;
    height: 50%;
  }
}
</style>
