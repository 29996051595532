<template>
  <div class="date-range">
    <UIDatepicker :options="datePickerOptions" :title="$t('Start Time')" :value="dateRange.startTime" @update="updateDateRange('startTime', $event)" />
    <UIDatepicker :options="datePickerOptions" :title="$t('End Time')" :value="dateRange.endTime" @update="updateDateRange('endTime', $event)" />
  </div>
</template>

<script>
import { createComponent } from '@vue/composition-api';
import UIDatepicker from '@/core/shared/components/ui/UIDatepicker';

export default createComponent({
  components: {
    UIDatepicker,
  },
  props: {
    dateRange: {
      type: Object,
      required: true,
    },
  },
  setup (props, { emit }) {
    const datePickerOptions = {
      altFormat: 'F d, Y',
    };

    function updateDateRange (type, value) {
      emit('update:dateRange', {
        ...props.dateRange,
        [type]: value,
      });
    }

    return {
      datePickerOptions,
      updateDateRange,
    };
  },
});
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.date-range {
  display: grid;
  grid-gap: var(--spacingSm);

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
