<template>
  <Panel class="stat-panel" :small="small">
    <h2 class="f-header">{{ title }}</h2>
    <Loading class="tc loading" v-show="loading" />
    <div v-show="!loading" class="slotcontainer mt4">
      <slot/>
    </div>
    <div class="tooltip" v-if="tooltip">
      <MoreInfoTooltip>
        <p class="f-tiny ">{{ tooltip }}</p>
      </MoreInfoTooltip>
    </div>
  </Panel>
</template>

<script>
import Loading from '@/core/shared/components/Loading';
import Panel from '@/core/shared/components/Panel';
import MoreInfoTooltip from '@/core/shared/components/MoreInfoTooltip';

export default {
  components: {
    Loading,
    Panel,
    MoreInfoTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.stat-panel {
  position: relative;

  & .tooltip {
    position: absolute;
    right: 0;
    top: var(--spacingSm);
    z-index: 5;
  }

}
</style>
